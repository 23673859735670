<template>
  <div v-loading="loading">
    <div v-if="shareFlag" class="share-msg">
      共享成功,请前往工作台
      <span class="share-center" @click="goto">共享中心</span> 查看
    </div>
    <div v-else class="share-msg">共享失败,请确认共享信息是否正确！</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SUCCESS_CODE: 0,
      loading: false,
      shareFlag: false,
    };
  },
  created() {
    let shareCode = this.$route.query.shareCode;
    this.useShareCode(shareCode);
  },
  methods: {
    useShareCode(uuid) {
      let params = {
        uuid,
      };
      this.loading = true;
      this.$http
        .post("/share-info/useShareCode", null, { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success(res.msg);
            this.shareFlag = true;
          } else {
            this.$message.error(res.msg);
            this.shareFlag = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
          this.shareFlag = false;
        });
    },
    goto() {
      this.$router.push("/home?current=4");
    },
  },
};
</script>

<style lang="less" scoped>
.share-msg {
  padding-top: 20%;
  text-align: center;
  color: #333;
  font-size: 16px;
  .share-center {
    color: #8565f6;
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>